import { IPaymentDetails } from '../types';

export const isExpired = (paymentLink: IPaymentDetails) => {
  if (paymentLink) {
    if (paymentLink.meta.multiUseStart && paymentLink.meta.multiUseEnd) {
      return (
        Date.now() < paymentLink.meta.multiUseStart ||
        paymentLink.meta.multiUseEnd < Date.now()
      );
    }
    if (paymentLink.meta.expired) return true;
    if (paymentLink.meta.expiration)
      return paymentLink.meta.expiration < Date.now();
  }
  return true;
};
