import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useQuery } from '@tanstack/react-query';

import { isEqual } from 'lodash';
import { useTranslation } from 'next-export-i18n';
import { useSnackbar } from 'notistack';

import {
  addParty,
  doPostPaymentActions,
  getPaymentLinkById,
  getStatus,
} from '@/services';
import { useStore } from '@/store';
import {
  BillStatusEnum,
  CurrentStatuses,
  IPaymentStatus,
  LinkStatus,
  PaymentLinkEffectProps,
  PaymentLinkStatusEffectProps,
  usePostPaymentActionsProps,
} from '@/types';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import {
  getDiningSession,
  getSession,
  removeDiningSession,
  setDiningSession,
} from '@clubpay/customer-common-module/src/service/session/dsi';

import { isExpired } from '../utils';
import { useRestaurant } from './restaurant';

const PREV_PAYMENT_STATUS = 'PREV_PAYMENT_STATUS';

let init = false;
let shouldGoAhead = true;

export function usePaymentLink(props?: PaymentLinkEffectProps) {
  const { url } = useQlubRouter();
  const cc = url.cc;
  const slug = url.slug;
  const orderId = url.id;
  const hash = url.hash;

  const toggleLoader = useStore(state => state.toggleLoader);
  const setCurrentStatus = useStore(state => state.setCurrentStatus);

  const { data, isFetched } = useRestaurant();
  const isLegacy = !data?.config?.paymentLinkNewFetchEnabled;

  const checkPrevPaymentStatus = () => {
    try {
      const prevPaymentStatus = JSON.parse(
        sessionStorage.getItem(PREV_PAYMENT_STATUS) || '{}',
      ) as IPaymentStatus;
      if (prevPaymentStatus?.bill?.status === BillStatusEnum.fullyPaid) {
        removeDiningSession(url);
        sessionStorage.removeItem(PREV_PAYMENT_STATUS);
      }
    } catch {
      // do nothing
    }
  };

  const query = useQuery({
    queryKey: ['payment-link-details', cc, slug, orderId, hash, isLegacy],
    queryFn: () => {
      checkPrevPaymentStatus();
      const existingDiningSession = getDiningSession(url);
      if (props?.shouldUpdateLoader) toggleLoader({ linkDetails: true });
      return getPaymentLinkById({
        cc,
        restaurantUnique: slug,
        orderId,
        hash,
        diningSessionID: existingDiningSession?.id,
        legacy: isLegacy,
      }).then(async res => {
        if (!existingDiningSession?.id)
          setDiningSession(url, { id: res.diningSessionID, jwt: res.JWTToken });
        const diningSession = getDiningSession(url);
        // in multi-use payment links, new dining sessions are created for every payment

        if (props?.shouldUpdateStatus) {
          if (res && isExpired(res)) setCurrentStatus(CurrentStatuses.EXPIRED);
          else if (res && res.meta.status === LinkStatus.DISCARDED)
            setCurrentStatus(CurrentStatuses.DISCARDED);
          else if (
            res &&
            !res.meta.isMultiUse &&
            (res.meta.status === LinkStatus.PAID ||
              Number(res.bill.remaining) <= 0)
          )
            setCurrentStatus(CurrentStatuses.PAID);
        }

        if (!init) {
          init = true;
          if (res.diningSessionID !== '') {
            await addParty({
              id: getSession(),
              diningSessionID: diningSession?.id || '',
              country: cc,
              restaurantUnique: slug,
            });
          }
        }

        if (props?.shouldUpdateLoader) toggleLoader({ linkDetails: false });
        return res;
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!cc && !!slug && isFetched,
  });

  return query;
}

export function usePaymentLinkStatus({
  reference = '',
  enabled = true,
}: PaymentLinkStatusEffectProps) {
  const { url } = useQlubRouter();
  const dsi = getDiningSession(url)?.id || '';
  const id = getSession();

  const query = useQuery({
    queryKey: ['payment-status', dsi, id, reference],
    queryFn: async () => {
      const statusRes = await getStatus({
        diningSessionID: dsi,
        id,
        reference: reference,
      });
      sessionStorage.setItem(PREV_PAYMENT_STATUS, JSON.stringify(statusRes));
      return statusRes;
    },
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    enabled: !!reference && !!dsi && enabled,
    retry: false,
  });

  return query;
}

export function useCheckLinkUpdates() {
  const { t } = useTranslation();
  const { lang } = useRouter().query;
  const { lang: qlang } = useQlubRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = usePaymentLink();
  const setInitialLinkData = useStore(state => state.setInitialLinkData);
  const initialLink = useStore(state => state.initialLink);

  useEffect(() => {
    if (shouldGoAhead && data && (lang === qlang || lang === undefined)) {
      const d = {
        billAmount: data.bill.billAmount!,
        currency: data.currency!,
        reference: data.meta.reference || '',
        description: data.meta.description || '',
      };
      if (
        initialLink?.sequentialID === data.meta.sequentialID &&
        !isEqual(initialLink?.data, d)
      )
        enqueueSnackbar(
          t(
            'details-of-the-payment-link-was-modified-please-review-and-proceed',
          ),
          {
            variant: 'info',
            autoHideDuration: 5000,
          },
        );
      setInitialLinkData({
        sequentialID: data.meta.sequentialID!,
        data: d,
      });
      shouldGoAhead = false;
    }
  }, [data, lang, qlang]);
}

export function usePostPaymentActions({
  ref,
  enabled = true,
}: usePostPaymentActionsProps) {
  const { url } = useQlubRouter();
  const dsi = getDiningSession(url)?.id || '';
  const userInfo = useStore(state => state.userInfo);
  const setUserInfo = useStore(state => state.setUserInfo);

  const handlePostPaymentActions = async (reference: string) =>
    await doPostPaymentActions({
      dsi,
      email: userInfo?.email,
      phone: userInfo?.phone,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      reference,
    });

  useEffect(() => {
    if (ref && enabled && !userInfo?.postPaymentActionsDone) {
      handlePostPaymentActions(ref).then(() => {
        if (userInfo)
          setUserInfo({ ...userInfo, postPaymentActionsDone: true });
      });
    }
  }, [ref, enabled]);

  useEffect(() => {
    if (userInfo && dsi && userInfo.dsi !== dsi) setUserInfo({ dsi });
  }, []);
}
